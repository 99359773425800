import { createGlobalState } from 'react-hooks-global-state'
import { AlgoliaDefaultFacets } from '../types/facets'
import DefaultBandConfigurations from '../types/default-band-configurations'
import { GenreFilterState, LocationState, RateLowState } from '../types/state'

export const defaultArtistGenreFacetFilters = AlgoliaDefaultFacets.reduce(
  (current, facet) => ({ ...current, [facet]: [] }),
  {} as GenreFilterState
)

const initialState = {
  query: '',
  genreFilters: defaultArtistGenreFacetFilters,
  // rateLow: {
  //   from: null,
  //   to: null,
  // } as RateLowState,
  price: {
    low: null,
    high: null,
  } as RateLowState,
  location: null as LocationState,
  aroundRadiusMiles: 200,
  // bandConfiguration: DefaultBandConfiguration,
  bandConfigurations: DefaultBandConfigurations,
  algoliaIndexDescription: 'Sort By',
}
const searchState = createGlobalState(initialState)

export const initialGlobalSearchState = initialState

export default searchState
