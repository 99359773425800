import { useEffect, useState } from 'react'
import {
  Dimensions,
  ScaledSize,
  Platform,
  useWindowDimensions,
} from 'react-native'

// export function useDimensions() {
//   const [dimensions, setDimensions] = useState({
//     window: Dimensions.get('window'),
//     screen: Dimensions.get('screen'),
//   })

//   const onChange = ({
//     window,
//     screen,
//   }: {
//     window: ScaledSize
//     screen: ScaledSize
//   }) => {
//     setDimensions({ window, screen })
//   }

//   useEffect(() => {
//     if (Platform.OS !== 'web') {
//       Dimensions.addEventListener('change', onChange)
//     }

//     return () => {
//       if (Platform.OS !== 'web') {
//         Dimensions.removeEventListener('change', onChange)
//       }
//     }
//   }, [])

//   return dimensions
// }

const useNativeWindowDimensions = Platform.select({
  web: () => ({ height: 0, width: 0 }),
  default: useWindowDimensions,
})

const numberToWebPx = (value: number | string) =>
  typeof value === 'number' && Platform.OS === 'web' ? `${value}px` : value

export function useResponsive() {
  const { width, height } = useNativeWindowDimensions()

  const vw = (percent: number): string => {
    return Platform.select({
      web: `${percent}vw`,
      default: ((percent * width) / 100) as any,
    })
  }
  const vh = (percent: number): string => {
    return Platform.select({
      web: `${percent}vh`,
      default: ((percent * height) / 100) as any,
    })
  }
  return {
    vw,
    vh,
  }
}

export default useResponsive

export function useCalc() {
  const calc = (...valuesToCalculate: string[]): string => {
    return Platform.select({
      web: `calc(${valuesToCalculate.join(' ')})`,
      default: valuesToCalculate[0] as any,
    })
  }
  const add = (prev: number, next: number): string => {
    return Platform.select({
      web: `${numberToWebPx(prev)} + ${numberToWebPx(next)}`,
      default: (prev + next) as any,
    })
  }
  const subtract = (prev: number, next: number): string => {
    return Platform.select({
      web: `${numberToWebPx(prev)} - ${numberToWebPx(next)}`,
      default: (prev - next) as any,
    })
  }
  const divide = (
    numerator: number | string,
    denominator: number | string
  ): string => {
    return Platform.select({
      web: `${numberToWebPx(numerator)} / ${numberToWebPx(denominator)}`,
      default: (Number(numerator) / Number(denominator)) as any,
    })
  }
  const multiply = (prev: number, next: number): string => {
    return Platform.select({
      web: `${numberToWebPx(prev)} * ${numberToWebPx(next)}`,
      default: (Number(prev) * Number(next)) as any,
    })
  }
  const min = (prev: number | string, next: number | string) => {
    return Platform.select({
      web: `min(${prev}, ${next})`,
      default: Math.min(prev as number, next as number) as any,
    })
  }
  const max = (prev: number | string, next: number | string) => {
    return Platform.select({
      web: `max(${prev}, ${next})`,
      default: Math.max(prev as number, next as number) as any,
    })
  }

  return {
    calc,
    add,
    divide,
    multiply,
    subtract,
    min,
    max,
  }
}
