import React from 'react'
import { View, Text, useDripsyTheme } from 'dripsy'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import Ionicons, { IconsBaseProps } from '../ionicons'
import { TouchableOpacity } from 'react-native'
import { SxStyleProp } from '@theme-ui/core'
import Press from '@beatgig/components/press'

type Props = {
  children: React.ReactNode
  icon?: boolean | 'hover'
  color?: boolean | string
  iconColor?: string
  underline?: boolean
  block?: boolean
  onPress?: () => void
  textSx?: SxStyleProp
  iconName?: IconsBaseProps['name']
  href?: string
  isText?: boolean
} & Omit<React.ComponentProps<typeof Press>, 'onPress'>

const Link = React.forwardRef(function Link(props: Props, outerRef) {
  const {
    sx = {},
    children,
    color = false,
    iconName = 'open-outline',
    icon = false,
    textSx = {},
    block,
    onPress,
    disabled = !onPress && !icon,
    isText = true,
    iconColor: iconColorProp,
    ...rest
  } = props

  const { colors, space } = useDripsyTheme().theme
  let mainColor: string
  if (typeof color === 'boolean') {
    mainColor = color ? 'primary' : 'text'
  } else {
    mainColor = color
  }
  const iconColor = iconColorProp
    ? colors?.[iconColorProp] ?? iconColorProp
    : colors?.[mainColor]

  return (
    <Press
      ref={outerRef as any}
      {...rest}
      onPress={onPress}
      disabled={disabled}
    >
      {({ hovered, pressed }) => {
        let blockStyle = {}
        if (block) {
          blockStyle = {
            px: 2,
            py: 2,
            borderRadius: 2,
            bg: hovered ? `${colors?.[mainColor]}30` : 'transparent',

            transitionProperty: 'background-color',
            transitionDuration: `250ms`,
            transitionTimingFunction: `cubic-bezier(.2,.8,.2,1)`,
          }
        }
        let showIcon = !!icon
        if (icon === 'hover') {
          showIcon = !!(hovered || pressed)
        }
        return (
          <>
            <View
              sx={{
                alignItems: 'center',
                flexDirection: 'row',
                // TODO change to moti
                ...blockStyle,
                ...sx,
              }}
            >
              {isText ? (
                <Text
                  selectable={false}
                  sx={{ color: mainColor, fontSize: 3, ...textSx }}
                >
                  {children}
                </Text>
              ) : (
                children
              )}
              {!!icon && (
                <Ionicons
                  name={iconName}
                  selectable={false}
                  size={20}
                  color={iconColor as string}
                  sx={{
                    marginLeft: 1,
                    opacity: showIcon ? 1 : 0,
                    // TODO change to moti
                    transitionDuration: '0.25s',
                    transitionTimingFunction: 'ease-in-out',
                    transitionProperty: 'opacity, transform',
                    transform: [
                      {
                        translateX: showIcon ? 0 : -5,
                      },
                    ],
                  }}
                />
              )}
            </View>
          </>
        )
      }}
    </Press>
  )
})

export default Link
